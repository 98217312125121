import { TimeEntries } from './src/time_entries';
import { DateSetup } from './src/dateSetup';
import { Shifts } from './src/shifts';
import { SplitShifts } from './src/split_shifts';

$(document).ready(function() {
  TimeEntries.setup();
  DateSetup.setup();
  Shifts.setup();
  (new SplitShifts()).setup();
});
