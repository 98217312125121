export class Shifts {
  static setup() {
    $('.copy-previous-shift-users-button').click(function() {
      var textToCopy = $(this).data('clipboard-text');
      navigator.clipboard.writeText(textToCopy)
        .then(() => window.alert('Successfully Copied!'))
        .catch(() => {
          $('#text-to-copy').val(textToCopy);
          document.getElementById('copy-dialog').showModal();
        });
    });
  }
}
